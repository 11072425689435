@use "@xws/design" as *;
@use "@xws/design/vars" as *;


body {
  overflow: hidden;
}

.context-menu-main {
  box-shadow: 0 2px 2px 0 #56565688 !important;
}
